import gate1 from "../assets/tiles/finish1.png";

export default class Finish {
  html() {
    return <img src={gate1} alt='gate'/>
  }

  enter() {
    return
  }
}